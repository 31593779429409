angular.module('angularApplication').controller("ChampagneSliderController", ["$scope", function($scope) {
    var _controller = this;


    /**
     * SliderManagement
     */
    _controller.activeSlider = 'champagne';

    _controller.toggleSlider = function() {
        _controller.popup.close();
        _controller.activeSlider = (_controller.activeSlider == 'champagne' ? 'spumante' : 'champagne');
    };


    /**
     * PopupManagement
     */
    _controller.popup = {};
    _controller.popup.template = '';
    _controller.popup.isActive = false;


    _controller.popup.open = function(type, file) {
        _controller.popup.template = 'gr/'+type+'/'+file+'.html';
        _controller.popup.isActive = true;
    };

    _controller.popup.close = function() {
        _controller.popup.isActive = false;
        _controller.popup.template = '';
    };


    (function init() {

    }());
}]);

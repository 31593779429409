APP.directive('grSlider', [function grSliderDirective() {
    var _directive = this;


    _directive.controller = ["$scope", "$element", "$attrs", "$parse", "$compile", function($scope, $element, $attrs, $parse, $compile) {
        var _controller = this, _options, _slider, _sliderItemClickHandler;

        /**
         * Setup default options
         * @type {{slidesToShow: number, slidesToScroll: number}}
         * @private
         */
        _options = {
            slidesToShow: 7,
            slidesToScroll: 1,

            prevArrow: '<button class="btn btn-none hidden-xs prev"><span>&lt;</span></button>',
            nextArrow: '<button class="btn btn-none hidden-xs next"><span>&gt;</span></button>',

            responsive: [{
                breakpoint: 991,
                settings: {
                    slidesToShow: 5
                }
            },{
                breakpoint: 767,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    centerMode: true
                }
            }]
        };


        /**
         * Shortlink for calling slick
         *
         * @param a1
         * @param a2
         * @param a3
         * @private
         */
        _slider = function(a1, a2, a3) {
            $element.slick(a1, a1, a3)
        };

        _sliderItemClickHandler = function($event) {
            var _item = angular.element(this);

            $scope.$apply(function() {
                $parse(_item.attr('gr-slider-click'))($scope, {$event: $event});
            });
        };


        /**
         * Log Data
         * @param data
         */
        _controller.log = function(data) {
            console.debug(data);
        };




        /**
         * Init directive controller
         */
        (function init() {

            //Use custom controllerAs logic
            if($attrs.grSlider) {
                $scope[$attrs.grSlider] = _controller;
            }

            if($attrs.hasOwnProperty('grSliderOptions') && angular.isObject($attrs.grSliderOptions)) {
                angular.extend(_options, $attrs.grSliderOptions);
            }

            _slider(_options);
            $element.on('click.grSlider', '[gr-slider-click]', _sliderItemClickHandler);
        }());
    }];

    return _directive;
}]);

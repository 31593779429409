
/**
 * Main JS - File
 */

//  Global Project Var
var initObject,
    APP;

(function($) {
    "use strict";

    var s;

    initObject = {
        settings: {
            modules: [
                'ngAnimate'
            ]
        },

        setMailLink: function() {
            jQuery('a.jsMailLink').attr('href', ['mailto',this.getMailLink()].join(':'));
        },
        getMailLink: function() {
            var href = location.href.split('/'),
                addr = 'office',
                host = (function() {
                    var host = href[href.length-2].split('.');
                    return [host[host.length-2],host[host.length-1]].join('.');
                }());

            return [addr,host].join('.');
        },

        init: function() {
            s = this.settings;

            this.setMailLink();

            //Bootstrap Angular App
            APP = angular.module('angularApplication', s.modules);
        }
    };

    initObject.init();
}(jQuery));



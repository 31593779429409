/*
angular.module('angularApplication')
    .animation('.jsAnimationScaleIn', function() {
        return {
            beforeAddClass: function(element, className, done) {
                if(className == 'ng-hide') {

                    // set initial Styles before Animation
                    element.css({
                        'overflow': 'hidden',
                        'transform': 'scale(1)'
                    });

                    // slide Out Element
                    jQuery(element).velocity({
                        scale: 0
                    }, done);


                    return function() {
                        //remove the style so that the CSS inheritance kicks in
                        element[0].style.transform = '';
                        element[0].style.overflow = '';
                    }

                } else {
                    done();
                }
            }, removeClass: function(element, className, done) {

                if(className == 'ng-hide') {

                    // set initial Styles before Animation

                    element.css({
                        'overflow': 'hidden',
                        'transform': 'scale(0)'
                    });

                    // slide the Element In
                    jQuery(element).velocity({
                        scale: 1
                    }, done);

                    return function() {
                        //remove the style so that the CSS inheritance kicks in
                        element[0].style.overflow = '';
                        element[0].style.transform = '';

                    }

                } else {
                    done();
                }
            }
        };
    });


*/
